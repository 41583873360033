import createReducer from "../helpers/createReducer";
import {END_LESSON, START_LESSON} from "../actionTypes/currentLessonPathTpes";


const defaultState = {
    lessonPath:""
}

const reducers = {
    [START_LESSON](state, {lessonPath}){
        localStorage.setItem('lessonPath',lessonPath)
        return {
            ...state,
            lessonPath
        }
    },
    [END_LESSON](state){
        localStorage.removeItem("lessonPath")
        return{
            lessonPath:''
        }
    }
}

export default createReducer(defaultState, reducers);

import SessionApi from "../api/sessionApi";
import { showMessage, startLoader, stopLoader } from "./loaderActions";
import {currentYear} from "../utils/DateUtils";
import _ from "lodash";

export const UPLOAD_IMAGE_START = "UPLOAD_IMAGE_START";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_ERROR = "UPLOAD_IMAGE_ERROR";

export const GET_APP_LOGO_START = "GET_APP_LOGO_START";
export const GET_APP_LOGO_SUCCESS = "GET_APP_LOGO_SUCCESS";
export const GET_APP_LOGO_ERROR = "GET_APP_LOGO_ERROR";

export const GET_STUDY_YEARS_START = "GET_STUDY_YEARS_START";
export const GET_STUDY_YEARS_SUCCESS = "GET_STUDY_YEARS_SUCCESS";
export const GET_STUDY_YEARS_ERROR = "GET_STUDY_YEARS_ERROR";

export const GET_QUARTER_LIST_START = "GET_QUARTER_LIST_START";
export const GET_QUARTER_LIST_SUCCESS = "GET_QUARTER_LIST_SUCCESS";
export const GET_QUARTER_LIST_ERROR = "GET_QUARTER_LIST_ERROR";

export const GET_CURRENT_SCHOOL_START = "GET_CURRENT_SCHOOL_START";
export const GET_CURRENT_SCHOOL_SUCCESS = "GET_CURRENT_SCHOOL_SUCCESS";
export const GET_CURRENT_SCHOOL_ERROR = "GET_CURRENT_SCHOOL_ERROR";

export const GET_MARKING_SYSTEMS_START = "GET_MARKING_SYSTEMS_START";
export const GET_MARKING_SYSTEMS_SUCCESS = "GET_MARKING_SYSTEMS_SUCCESS";
export const GET_MARKING_SYSTEMS_ERROR = "GET_MARKING_SYSTEMS_ERROR";

export const RESET_IMAGE = "RESET_IMAGE";
export const SET_IMAGE = "RESET_IMAGE";

export const GET_NATIONALITIES_START = "GET_NATIONALITIES_START";
export const GET_NATIONALITIES_SUCCESS = "GET_NATIONALITIES_SUCCESS";
export const GET_NATIONALITIES_ERROR = "GET_NATIONALITIES_ERROR";
export const GET_GENDERS_START = "GET_GENDERS_START";
export const GET_GENDERS_SUCCESS = "GET_GENDERS_SUCCESS";
export const GET_GENDERS_ERROR = "GET_GENDERS_ERROR";
export const GET_PAYROLLS_START = "GET_PAYROLLS_START";
export const GET_PAYROLLS_SUCCESS = "GET_PAYROLLS_SUCCESS";
export const GET_PAYROLLS_ERROR = "GET_PAYROLLS_ERROR";
export const GET_ROOMS_START = "GET_ROOMS_START";
export const GET_ROOMS_SUCCESS = "GET_ROOMS_SUCCESS";
export const GET_ROOMS_ERROR = "GET_ROOMS_ERROR";
export const GET_SUBJECT_START = "GET_SUBJECT_START";
export const GET_SUBJECT_SUCCESS = "GET_SUBJECT_SUCCESS";
export const GET_SUBJECT_ERROR = "GET_SUBJECT_ERROR";
export const SET_STUDY_YEAR = "SET_STUDY_YEAR";
export const MODAL_VISIBLE = "MODAL_VISIBLE";
export const SET_COPY_SCHEDULE_DETAILS = "SET_COPY_SCHEDULE_DETAILS";

export const SET_REPLACEMENT_ITEM = "SET_REPLACEMENT_ITEM";
export const RESET_REPLACEMENT_ITEM = "RESET_REPLACEMENT_ITEM";

export function setReplacementItem(data) {
	return (dispatch) => {
		dispatch({
			type: SET_REPLACEMENT_ITEM,
			data
		})
	}
}

export function resetReplacementItem() {
	return (dispatch) => {
		dispatch({
			type: RESET_REPLACEMENT_ITEM
		})
	}
}

export function uploadImage(file) {
	return (dispatch) => {
		return dispatch(uploadImageApi(file))
			.then((response) => {
				dispatch(stopLoader());
				return response;
			})
			.catch((error) => {
				dispatch(stopLoader(error));
			});
	};
}

export function getFormData() {
	return (dispatch,getState) => {
		const {session:{yearId}} = getState()
		const params = {
			page: 1,
			page_size: 50,
		};
		dispatch(startLoader());
		const requests = [
			dispatch(getGenders()),
			dispatch(getRooms(params)),
			dispatch(getSubject(params)),
			// dispatch(getPayrolls(params)),
			dispatch(getNationalities(params)),
			dispatch(getAppLogo()),
			dispatch(getCurrenSchool()),
			dispatch(getMarkingSystems()),
			dispatch(getStudyYears())
				.then(res => {
				const currentYearItem = currentYear(res?.results);
				dispatch(getQuarters({school_year: yearId || _.get(currentYearItem, 'id')}));
				dispatch(setStudyYear({id: yearId || _.get(currentYearItem, 'id')}))
			})
				.catch((error) => {
				dispatch(stopLoader(error));
			}),
		];

		return Promise.all(requests)
			.then(() => {
				dispatch(stopLoader());
			})
			.catch((error) => {
				dispatch(stopLoader(error));
			});
	};
}

export function getSchoolLogo() {
	return (dispatch) => {
		dispatch(getAppLogo()).catch((error) => {
			showMessage(error);
		});
	};
}

export function getNationalities(data) {
	return (dispatch) => {
		return dispatch(getNationalitiesApi(data)).catch((error) => {
			showMessage(error);
		});
	};
}

export function getPayrolls(data) {
	return (dispatch) => {
		return dispatch(getPayrollsApi(data)).catch((error) => {
			showMessage(error);
		});
	};
}

export function getGenders() {
	return (dispatch) => {
		return dispatch(getGendersApi()).catch((error) => {
			showMessage(error);
		});
	};
}

export function getRooms() {
	return (dispatch) => {
		return dispatch(getRoomsApi()).catch((error) => {
			showMessage(error);
		});
	};
}

export function getSubject(data) {
	return (dispatch) => {
		return dispatch(getSubjectApi(data)).catch((error) => {
			showMessage(error);
		});
	};
}

export function resetImage() {
	return (dispatch) => {
		dispatch({ type: RESET_IMAGE });
	};
}

export function setImage(image) {
	return (dispatch) => {
		dispatch({ type: SET_IMAGE, image });
	};
}
export function handleQuarterYear(id) {
	return (dispatch) => {
		dispatch(getQuarters({school_year:id}));
	};
}

export function setCopyScheduleDetails(data) {
	return (dispatch) => {
		dispatch({
			type: SET_COPY_SCHEDULE_DETAILS,
			data
		})
	}
}

export function modalVisibleHandler(_bool) {
	return (dispatch) => {
		dispatch({
			type: MODAL_VISIBLE,
			_bool
		})
	}
}

function getNationalitiesApi(data) {
	return {
		types: [
			GET_NATIONALITIES_START,
			GET_NATIONALITIES_SUCCESS,
			GET_NATIONALITIES_ERROR,
		],
		api: SessionApi.getNationalities,
		data,
	};
}

function getGendersApi() {
	return {
		types: [GET_GENDERS_START, GET_GENDERS_SUCCESS, GET_GENDERS_ERROR],
		api: SessionApi.getGenders,
	};
}

function getRoomsApi() {
	return {
		types: [GET_ROOMS_START, GET_ROOMS_SUCCESS, GET_ROOMS_ERROR],
		api: SessionApi.getRooms,
	};
}

function getSubjectApi(data) {
	return {
		types: [GET_SUBJECT_START, GET_SUBJECT_SUCCESS, GET_SUBJECT_ERROR],
		api: SessionApi.getSubject,
		data,
	};
}

function getPayrollsApi(data) {
	return {
		types: [GET_PAYROLLS_START, GET_PAYROLLS_SUCCESS, GET_PAYROLLS_ERROR],
		api: SessionApi.getPayrolls,
		data,
	};
}

function uploadImageApi(data) {
	return {
		api: SessionApi.upload,
		types: [UPLOAD_IMAGE_START, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_ERROR],
		data,
	};
}

export function getAppLogo() {
	return {
		types: [GET_APP_LOGO_START, GET_APP_LOGO_SUCCESS, GET_APP_LOGO_ERROR],
		api: SessionApi.getSchoolLogo,
	};
}

export function getStudyYears() {
	return {
		types: [GET_STUDY_YEARS_START, GET_STUDY_YEARS_SUCCESS, GET_STUDY_YEARS_ERROR],
		api: SessionApi.getStudyYearsApi,
	};
}

export function getQuarters(data) {
	return {
		types: [GET_QUARTER_LIST_START, GET_QUARTER_LIST_SUCCESS, GET_QUARTER_LIST_ERROR],
		api: SessionApi.getQuartersApi,
		data
	};
}

export function getCurrenSchool() {
	return {
		types: [GET_CURRENT_SCHOOL_START, GET_CURRENT_SCHOOL_SUCCESS, GET_CURRENT_SCHOOL_ERROR],
		api: SessionApi.getCurrentSchoolApi,
	};
}

export function getMarkingSystems() {
	return {
		types: [GET_MARKING_SYSTEMS_START, GET_MARKING_SYSTEMS_SUCCESS, GET_MARKING_SYSTEMS_ERROR],
		api: SessionApi.getMarkingSystemsApi,
	};
}

export function setStudyYear(data) {
	localStorage.setItem('yearId',data.id)
	return {
		type: SET_STUDY_YEAR,
		data,
	};
}
